import React from 'react';
import { BsTwitter, BsInstagram} from 'react-icons/bs';
import { FaFacebookF, FaYoutube, FaGithub } from 'react-icons/fa';

const SocialMedia = () => (
  <div className="app__social">
  <div>
    <a href="https://www.facebook.com/bozidar.cudina/" target="_blank">
      <FaFacebookF />
    </a>
  </div>
    <div>
      <a href="https://www.youtube.com/channel/UCaLTfiNcTlhpfnN4CfDQw2A?view_as=subscriber" target="_blank">
        <FaYoutube />
      </a>
    </div>
    <div>
      <a href="https://github.com/bcudina?tab=repositories" target="_blank">
        <FaGithub />
      </a>
    </div>
  </div>
);

export default SocialMedia;
